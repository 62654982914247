import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { privateSitePrefix } from '../Util/Config'
import { uniqByIdAndType } from '../Util/Object'
import { KeywordsNotFoundMessage } from '../Util/Message'
import * as Menu from '../State/Menu'
import * as Resources from '../State/Resources';
import * as ColleagueList from '../State/ColleagueList'
import * as Ui from './Ui'
import * as Styled from './ColleagueList.styled'
import LoadingBox from './Ui/LoadingBox'

const ColleagueListComponent = () => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const currentUser = useSelector(state => state.login.currentUser)
  const colleagues = useSelector(ColleagueList.selectColleagues)
  const organizations = useSelector(ColleagueList.selectOrganizations)
  const hasMore = useSelector(ColleagueList.selectHasMore)
  const loading = useSelector(ColleagueList.selectLoading)
  const messageSent = useSelector(ColleagueList.selectMessageSent)
  const isInviting = useSelector(ColleagueList.selectIsInviting)
  const filters = useSelector(ColleagueList.selectFilters)
  const search = useSelector(ColleagueList.selectSearch)
  const expertises = useSelector(ColleagueList.selectAvailableExpertises)
  const data = useSelector(ColleagueList.selectInvitationData)
  const keywordsNotFound = useSelector(ColleagueList.selectKeywordsNotFound)
  const nextPage = Math.floor((colleagues.length + 1) / Number(process.env.REACT_APP_DEFAULT_RESULT_LIMIT)) + 1
  const errorMessage = useSelector(state => state.colleagueList.errorMessage)
  const resourceLoading = useSelector(Resources.selectLoading)

  const {
    [Resources.RESOURCE_PATIENTS]: patients,
  } = useSelector(Resources.selectResources)

  useEffect(() => {
    dispatch(ColleagueList.fetch())
    dispatch(Menu.changeActive(Menu.MEDECINS))

    return () => dispatch(ColleagueList.clean())
  }, [dispatch])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  useEffect(() => {
    if (messageSent)
      alert.error("Votre invitation a bien été envoyée.", { timeout: 5000 })
  }, [alert, messageSent])

  const sendInvitation = (e) => {
    e.preventDefault()
    if (data.email.value) {
      dispatch(ColleagueList.addRecipient({ value: data.email.value.trim() }))
    }
    dispatch(ColleagueList.sendInvitation())
  }

  const updateField = name => e =>
    dispatch(ColleagueList.updateField({ name, value: e.target.value }))

  const updateEmailField = name => e => {
    dispatch(ColleagueList.updateField({ name, value: e.target.value }))
    if ([' ', ',', ';'].includes(e.target.value[e.target.value.length - 1])) {
      checkEmailsFromField(e.target.value);
    }
  }

  const blurEmailField = e => {
    if (e.target.value.length > 0) {
      checkEmailsFromField(e.target.value.trim());
    }
  }

  const checkEmailsFromField = (emailFieldValue) => {
    let emails = [];

    if (emailFieldValue.includes(',')) {
      emails = emailFieldValue.split(',');
    } else if (emailFieldValue.includes(';')) {
      emails = emailFieldValue.split(';');
    } else if (emailFieldValue.includes(' ')) {
      emails = emailFieldValue.split(' ');
    } else {
      emails.push(emailFieldValue);
    }

    emails.forEach((email) => {
      if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email.trim())) {
        dispatch(ColleagueList.addRecipient({ value: email }))
      } else if (email != '') {
        dispatch(ColleagueList.addFieldError({ name: 'email', error: email }))
      }
    });
  }

  const onUncheckRecipient = recipient => dispatch(ColleagueList.removeRecipient(recipient.trim()))

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.ColleagueListContainer>
          <Ui.Layout.PageContentWrapper>
            <Ui.Layout.PanelContainer>
              <Ui.Layout.Panel>
                <Ui.Layout.TelexpertiseThumbnailContainer>
                  <Ui.Layout.TelexpertiseLogo>
                    <Ui.Icon.IconDoctor />
                  </Ui.Layout.TelexpertiseLogo>
                  <Ui.BasicElement.H3 noMargin>Médecins</Ui.BasicElement.H3>
                </Ui.Layout.TelexpertiseThumbnailContainer>
                <Ui.BasicElement.P light>
                  Recherchez vos correspondants parmi les médecins inscrits
                  sur Skemeet, adressez-leur des patients et associez-les
                  à vos téléexpertises. Invitez vos confrères non membres à vous rejoindre.
                </Ui.BasicElement.P>
              </Ui.Layout.Panel>
            </Ui.Layout.PanelContainer>
            <Ui.Layout.Content hasPanel>
              {isInviting
                ? <Ui.Layout.Section padded>
                  <Ui.Layout.ContentPanel>
                    <Ui.Layout.ActionButtonsContainerFixed>
                      <Ui.Icon.IconClose
                        onClick={e => dispatch(ColleagueList.setIsInviting(false))}
                      />
                    </Ui.Layout.ActionButtonsContainerFixed>
                    <Ui.Layout.Form onSubmit={sendInvitation} noValidate>
                      <Ui.BasicElement.H2>
                        Inviter un confrère
                      </Ui.BasicElement.H2>
                      <Ui.Form.TextInput
                        id='invitation-email'
                        htmlFor="email"
                        label="Email *"
                        value={data.email.value}
                        onChange={updateEmailField('email')}
                        onInputBlur={blurEmailField}
                        error={data.email.errors.length > 0 && `L${data.email.errors.length > 1 ? 'es ' : '\''}adresse${data.email.errors.length > 1 ? 's ' : ''} email suivante${data.email.errors.length > 1 ? 's ' : ''} n'${data.email.errors.length > 1 ? 'ont ' : 'a'} pas pu être importée${data.email.errors.length > 1 ? 's' : ''}. Veuillez vérifier ${data.email.errors.length > 1 ? 'leur' : 'son'} format : [${data.email.errors.join(', ')}]`}
                        noSubmit
                      />
                      {data.recipients.value.length > 0 &&
                        <Ui.Layout.RadioGroupContainer id='emails-doctor-invitation'>
                          {data.recipients.value
                            .map((recipient, index) =>
                              <Styled.Recipient
                                key={`recipient_checkbox_${index}`}
                                value={recipient}
                                name={recipient}
                                label={recipient}
                                onChange={(e) => onUncheckRecipient(recipient)}
                                checked={true}
                              />
                            )}
                        </Ui.Layout.RadioGroupContainer>
                      }
                      {data.recipients.errors[0] &&
                        <Ui.Form.FieldErrorMessage>{data.recipients.errors[0]}</Ui.Form.FieldErrorMessage>
                      }
                      <Ui.Form.TextAreaInput
                        key="message"
                        htmlFor="message"
                        label="Message"
                        value={data.message.description}
                        onChange={updateField('message')}
                        noSubmit
                      />
                      <Ui.Form.InputContainer>
                        <Ui.BasicElement.H3>
                          Sélectionnez une téléexpertise
                        </Ui.BasicElement.H3>
                        <Ui.SearchBar.SearchAndCheck
                          name="expertises"
                          query={search.expertises.value}
                          TeleExpertise={ColleagueList}
                          resultToLabel={expertise => expertise.name}
                          resultToValue={expertise => ({ type: expertise.type, id: expertise.id, })}
                          resultSet={expertises.filter(uniqByIdAndType)}
                          list={data.expertises.value}
                          loading={search.expertises.loading}
                          CheckboxIconComponent={Ui.SearchBar.ExpertiseCheckboxIcon}
                        />
                      </Ui.Form.InputContainer>
                      <Ui.Form.InputContainer>
                        <Ui.BasicElement.H3>
                          Sélectionnez un patient
                        </Ui.BasicElement.H3>
                        <Ui.SearchBar.SearchAndCheck
                          isPurple
                          name="patients"
                          TeleExpertise={ColleagueList}
                          query={search.patients.value}
                          resultToLabel={patient => patient.fullName}
                          resultToValue={patient => patient.id}
                          resultSet={patients}
                          list={data.patients.value}
                          loading={resourceLoading.patients}
                          onSearch={(patientSelection, criteria) => patientSelection
                            .filter(({ fullName }) => RegExp(criteria, 'i').test(fullName))
                            .slice(0, 10)}
                          onToggle={(id, toggled, name = 'patients') =>
                            dispatch(ColleagueList.toggle({ value: id, toggled, name }))}
                        />
                      </Ui.Form.InputContainer>
                      <Ui.Layout.ButtonGroup>
                        <Ui.Button.BasicButton
                          type="submit"
                          isGreen
                          isFilled
                        >
                          Envoyer
                        </Ui.Button.BasicButton>
                        <Ui.Button.BasicButton
                          isGreen
                          onClick={() => dispatch(ColleagueList.setIsInviting(false))}
                        >
                          Annuler
                        </Ui.Button.BasicButton>
                      </Ui.Layout.ButtonGroup>
                    </Ui.Layout.Form>
                  </Ui.Layout.ContentPanel>
                </Ui.Layout.Section>
                : <>
                  <Ui.Search.FilterContainer>
                    <Ui.Search.FiltersAndSorting>
                      <Ui.Search.FilterSection>
                        <Ui.Select.Select
                          label="Afficher"
                          items={[
                            { label: 'Médecins', value: ColleagueList.DISPLAY_DOCTOR, selected: false },
                            { label: 'Organisations', value: ColleagueList.DISPLAY_ORGANIZATION, selected: false },
                          ]}
                          value={filters.displayBy}
                          onChange={e => dispatch(ColleagueList.changeDisplayBy(e.target.value))}
                        />
                        {ColleagueList.DISPLAY_DOCTOR === filters.displayBy &&
                          <Ui.Select.Select
                            label="Afficher"
                            items={[
                              {
                                label: 'Mes confrères',
                                value: ColleagueList.DISPLAY_MY_COLLEAGUES,
                                selected: ColleagueList.DISPLAY_MY_COLLEAGUES === filters.filterBy,
                              },
                              {
                                label: 'Tous les confrères',
                                value: ColleagueList.DISPLAY_ALL_COLLEAGUES,
                                selected: ColleagueList.DISPLAY_ALL_COLLEAGUES === filters.filterBy,
                              },
                            ]}
                            value={filters.filterBy}
                            onChange={e => dispatch(ColleagueList.changeFilterBy(e.target.value))}
                          />
                        }
                      </Ui.Search.FilterSection>
                      <div>
                        <Ui.Button.BasicButton
                          isFilled
                          isGreen
                          isLarge
                          onClick={e => dispatch(ColleagueList.setIsInviting(true))}
                        >
                          Inviter un confrère
                        </Ui.Button.BasicButton>
                      </div>
                    </Ui.Search.FiltersAndSorting>
                    <Ui.Search.FiltersAndSorting>
                      <Ui.SearchBar.SearchBar
                        placeholder="Rechercher un confrère par discipline, nom, prénom ou ville"
                        onChange={e => dispatch(ColleagueList.changeSearch(e.target.value))}
                        value={filters.search}
                      />
                    </Ui.Search.FiltersAndSorting>
                    <KeywordsNotFoundMessage
                      keywords={keywordsNotFound}
                      resourceType="colleague" />
                  </Ui.Search.FilterContainer>

                  {ColleagueList.DISPLAY_DOCTOR === filters.displayBy
                    ? <>
                      <Ui.Card.Container>
                        <Ui.Search.MobileActionButtonGroup>
                          <Ui.Button.BasicButton
                            isFilled
                            isGreen
                            isLarge
                            onClick={e => dispatch(ColleagueList.setIsInviting(true))}
                          >
                            Inviter un confrère
                          </Ui.Button.BasicButton>
                        </Ui.Search.MobileActionButtonGroup>
                        <Ui.Search.CardContainer>
                          {colleagues.map((colleague, i) =>
                            <Ui.Colleague.Colleague
                              colleague={colleague}
                              to={`${privateSitePrefix}/medecins/${colleague.id}`}
                              key={`colleague_${colleague.id}`}
                              currentUser={currentUser}
                            />
                          )}
                        </Ui.Search.CardContainer>
                      </Ui.Card.Container>
                      {hasMore && colleagues.length > 0 && (
                        <Ui.Search.LoadMoreButtonContainer>
                          <Ui.Button.BasicButton
                            isFilled
                            isPrimary
                            isLarge
                            disabled={loading}
                            onClick={() => dispatch(ColleagueList.fetch({ page: nextPage }))}
                          >
                            {loading &&
                              <Ui.Global.Loader />
                            }
                            Charger plus
                          </Ui.Button.BasicButton>
                        </Ui.Search.LoadMoreButtonContainer>
                      )}
                    </>
                    : <Ui.Card.Container>
                      {loading
                        ? <Ui.Global.Loader />
                        : <Ui.Layout.FlexBox flow="row wrap">
                          {organizations.map((organization, i) =>
                            <Ui.Card.OrganizationCard
                              id={organization.id}
                              name={organization.name}
                              logo={organization.logo}
                              currentUserManagerOrMember={organization.currentUserManagerOrMember}
                            />
                          )}
                        </Ui.Layout.FlexBox>
                      }
                    </Ui.Card.Container>
                  }
                </>
              }
            </Ui.Layout.Content>
          </Ui.Layout.PageContentWrapper>
        </Styled.ColleagueListContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

export default ColleagueListComponent;
