import * as React from "react";

export const Check = ({
  color='white',
  ...props
}) => (
  <svg
    width={11}
    height={11}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Path Copy 5"
      d="M4.22234 10.5C3.89624 10.5 3.58783 10.3546 3.38956 10.1051L0.711826 6.73412C0.363547 6.29568 0.454213 5.67139 0.91414 5.33925C1.37482 5.00696 2.02926 5.09396 2.37769 5.53211L4.13887 7.74898L8.56834 0.969219C8.87331 0.502787 9.51727 0.360072 10.0076 0.65036C10.4974 0.940933 10.6469 1.55522 10.3418 2.02208L5.10923 10.0307C4.92744 10.3091 4.61363 10.4843 4.27 10.4991C4.25396 10.4997 4.23823 10.5 4.22234 10.5Z"
      fill={color}
    />
  </svg>
);
