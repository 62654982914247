import { createSlice } from '@reduxjs/toolkit'

export const DISPLAY_DOCTOR = 'typeDoctor'
export const DISPLAY_ORGANIZATION = 'typeOrganization'
export const DISPLAY_MY_COLLEAGUES = 'mine'
export const DISPLAY_ALL_COLLEAGUES = 'all'

export const MODE_RECEIVED_MORE = 'received more'
export const MODE_RECEIVED_FIRST = 'received first'

export const INITIAL_STATE = {
  data: [],
  organizations: [],
  keywordsNotFound: [],
  invitationData: {
    email: { value: '', errors: [], validationRules: [] },
    expertises: { value: [], errors: [], validationRules: [] },
    patients: { value: [], errors: [], validationRules: [] },
    message: { value: '', errors: [], validationRules: [] },
    recipients: { value: [], errors: [], validationRules: ['emailList'] },
  },
  hasMore: true,
  loading: false,
  pending: false,
  error: false,
  success: false,
  messageSent: false,
  errorMessage: null,
  filters: {
    displayBy: DISPLAY_DOCTOR,
    filterBy: DISPLAY_ALL_COLLEAGUES,
    search: '',
  },
  isInviting: false,
  search: {
    colleagues: { value: '', loading: false },
    expertises: { value: '', loading: false },
    patients: { value: '', loading: false },
    opinions: { value: '', loading: false },
    staffs: { value: '', loading: false },
    cohorts: { value: '', loading: false },
  },
  availableExpertises: [],
}

const slice = createSlice({
  name: 'colleagueList',
  initialState: INITIAL_STATE,
  reducers: {

    toggle: (state, { payload: { value, toggled, name } }) => ({
      ...state,
      invitationData: {
        ...state.invitationData,
        [name]: {
          ...state.invitationData[name],
          value: toggled
            ? [...state.invitationData[name].value, value]
            : state.invitationData[name].value.filter(e => value.id !== e.id),
        },
      },
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value: '',
        }
      },
      error: false,
      errorMessage: null,
    }),

    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      invitationData: {
        ...state.invitationData,
        [name]: {
          ...state.invitationData[name],
          value,
          errors,
        },
      },
    }),

    addFieldError: (state, { payload: { name, error } }) => ({
      ...state,
      invitationData: {
        ...state.invitationData,
        [name]: {
          ...state.invitationData[name],
          errors: [...(state.invitationData[name]?.errors || []), error],
        },
      },
    }),

    addRecipient: (state, { payload: { name, value } }) => ({
      ...state,
      invitationData: {
        ...state.invitationData,
        recipients: {
          ...state.invitationData.recipients,
          value: [
            ...state.invitationData.recipients.value,
            value
          ],
        },
        email: {
          ...state.invitationData.email,
          value: '',
        }
      },
    }),

    changeDisplayBy: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        displayBy: payload,
      },
    }),

    changeFilterBy: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        filterBy: payload,
        search:
            state.filters.search.length > 0 &&
            payload === DISPLAY_MY_COLLEAGUES
          ? ''
          : state.filters.search,
      },
    }),

    changeSearch: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        filterBy: state.filters.search.length > 0
          ? DISPLAY_ALL_COLLEAGUES
          : state.filters.filterBy,
        search: payload,
      },
    }),

    removeRecipient: (state, { payload }) => ({
      ...state,
      invitationData: {
        ...state.invitationData,
        recipients: {
          ...state.invitationData.recipients,
          value: [
            ...state.invitationData.recipients.value.filter(recipient => recipient !== payload),
          ]
        }
      }
    }),

    fetch: state => ({
      ...state,
      loading: true,
    }),

    received: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload.mode === MODE_RECEIVED_FIRST
        ? payload.data
        : [...state.data, ...payload.data],
      hasMore: Number(process.env.REACT_APP_DEFAULT_RESULT_LIMIT) > payload.data.length
        ? false
        : true,
      keywordsNotFound: payload.keywordsNotFound,
    }),

    receivedOrganizations: (state, { payload }) => ({
      ...state,
      loading: false,
      organizations: payload
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    success: (state, { payload: id }) => ({
      ...state,
      pending: false,
      error: false,
      success: true,
      colleagueId: id
    }),

    invitationSuccess: (state, { payload: id }) => ({
      ...state,
      pending: false,
      error: false,
      messageSent: true,
      colleagueId: id
    }),

    invalidate: (state, { payload }) => ({
      ...state,
      pending: false,
      error: true,
      errorMessage: payload,
    }),

    setIsInviting: (state, { payload }) => ({
      ...state,
      isInviting: payload,
    }),

    fetchAvailableExpertises: (state, { payload }) => ({
      ...state,
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: true
        }
      },
      availableExpertises: state.availableExpertises.filter(exp =>
        state.invitationData.expertises.value.find(expertise =>
          expertise.type === exp.type && Number(expertise.id) === Number(exp.id)
        ))
    }),

    receivedAvailableExpertises: (state, { payload }) => ({
      ...state,
      loading: false,
      availableExpertises: [
        ...state.availableExpertises,
        ...payload
      ], search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: false
        }
      }
    }),

    sendInvitation: state => ({
      ...state,
      pending: true,
      error: false,
      success: false,
      invitationData: Object
        .entries(state.invitationData)
        .reduce((acc, [name, field]) => ({
          ...acc,
          [name]: { ...field, errors: [] },
        }), state.invitationData),
    }),

    setQuery: (state, { payload: { name, value } }) => ({
      ...state,
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value
        }
      }
    }),

    clean: state => INITIAL_STATE,

    cleanInvitation: state => ({
      ...state,
      invitationData: {
        email: { value: '', errors: [], validationRules: [] },
        expertises: { value: [], errors: [], validationRules: [] },
        patients: { value: [], errors: [], validationRules: [] },
        message: { value: '', errors: [], validationRules: [] },
        recipients: { value: [], errors: [], validationRules: ['emailList'] },
      },
      messageSent: false,
    })
  }
})

export default slice.reducer

export const {
  updateField,
  addFieldError,
  addRecipient,
  toggle,
  removeRecipient,
  changeDisplayBy,
  changeFilterBy,
  changeSearch,
  fetch,
  received,
  receivedOrganizations,
  check,
  uncheck,
  apiError,
  success,
  invitationSuccess,
  setIsInviting,
  setQuery,
  fetchAvailableExpertises,
  receivedAvailableExpertises,
  sendInvitation,
  invalidate,
  clean,
  cleanInvitation,
} = slice.actions

export const selectColleagues = state => state.colleagueList.data
export const selectKeywordsNotFound = state => state.colleagueList.keywordsNotFound
export const selectOrganizations = state => state.colleagueList.organizations
export const selectFilters = state => state.colleagueList.filters
export const selectHasMore = state => state.colleagueList.hasMore
export const selectLoading = state => state.colleagueList.loading
export const selectIsInviting = state => state.colleagueList.isInviting
export const selectAvailableExpertises = state => state.colleagueList.availableExpertises
export const selectInvitationData = state => state.colleagueList.invitationData
export const selectSearch = state => state.colleagueList.search
export const selectMessageSent = state => state.colleagueList.messageSent
