import * as React from "react";

export const Menu = ({
    color = 'white',
    ...props
}) => (
    <svg
        width={17}
        height={12}
        viewBox="0 0 17 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.904125 1.80867H9.63108C10.1304 1.80867 10.5352 1.40379 10.5352 0.904336C10.5352 0.404885 10.1304 0 9.63108 0H0.904125C0.404791 0 0 0.404885 0 0.904336C0 1.40379 0.404791 1.80867 0.904125 1.80867ZM0.904125 12H9.63108C10.1304 12 10.5352 11.5951 10.5352 11.0957C10.5352 10.5962 10.1304 10.1913 9.63108 10.1913H0.904125C0.404791 10.1913 0 10.5962 0 11.0957C0 11.5951 0.404791 12 0.904125 12ZM16.0959 6.93572H0.904125C0.404791 6.93572 0 6.53084 0 6.03138C0 5.53193 0.404791 5.12705 0.904125 5.12705H16.0959C16.5952 5.12705 17 5.53193 17 6.03138C17 6.53084 16.5952 6.93572 16.0959 6.93572Z"
            fill={color}
        />
    </svg>
);
