import { IconOk, IconPro } from './Icon'
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize';
import { privateSitePrefix } from '../../Util/Config'
import styled, { css } from 'styled-components';
import Editable from '../../Util/Editable'
import * as Ui from '../Ui'
import * as SearchBarUi from './SearchBar';
import * as SelectUi from './Select';
import * as IconUi from './Icon'
import * as LayoutUi from './Layout'
import * as ButtonUi from './Button'
import * as BasicElementUi from './BasicElement'
import UniqueDigitId from '../../Util/UniqueDigitId'
import { Link } from "react-router-dom";

const expertiseNameFromType = (type) =>
  type === 'staffs'
    ? 'Staff'
    : type === 'opinions'
      ? 'Avis'
      : type === 'cohorts'
        ? 'Cohorte'
        : null

export const Label = styled.label`
  color: ${props => props.isTitleWarning ? props.theme.cohorteRed : props.theme.grey} !important;
  font-size: .875rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: .7rem;
  position: relative;
  + textarea {
    box-sizing: border-box;
    margin-top: .5rem;
  }
  b {
    background-color: ${props => props.theme.grey2};
    color: ${props => props.theme.lightGrey};
    position: absolute;
    right: 0;
    top: .7rem;
    display: flex;
    margin-top: .5rem;
    z-index: 10;
    border: 1px solid;
    padding: .2rem;
    border-radius: 50px;
    width: 12px;
    height: 12px;
    align-items: center;
    justify-content: center;
    i {
      font-size: .7rem;
      font-style: normal;
    }
    &:hover, &:focus {
      cursor: pointer;
    }
  }
`

export const Input = styled.input`
  font-family: 'Montserrat', sans-serif;
  display: inline-flex;
  width: 100%;
  max-width: 100%;
  letter-spacing: 0.04rem;
  padding-bottom: .3rem;
  padding-top: .5rem;
  height: 2.5rem;
  border: 0;
  border-bottom: 1px solid;
  border-color: c;
  outline: none;
  font-size: 0.875rem;
  color: ${props => props.theme.darkBlue};
  box-sizing: border-box;
  background: transparent;
  font-style: ${props => props.italic ? 'italic' : 'normal'};
  margin-top: .3rem;
  margin-bottom: .2rem;
  padding-right: ${props => props.noSubmit ? '0' : '2.8rem'};

  &:hover {
    border-color: ${props => props.theme.primary};
    background-color:  ${props => props.readOnly ? props.theme.lightGrey4 : 'transparent'};
  }

  &:focus {
    border-color: ${props => props.theme.primary};
  }

  &:disabled {
    opacity: .6;
    border-bottom: 1px dotted;
  }

  &:read-only {
    border-color: transparent;
    opacity: 1;
  }

  &::placeholder {
    color: ${props => props.theme.grey};
    opacity: .6;
  }
`

export const InlineInput = styled(props => <Input {...props} />)`
  width: auto;
  padding: 0;
  margin-left: .3rem;
`

export const FieldErrorMessage = styled.div.attrs(props => ({ className: 'error' }))`
  margin-top: .3rem;
  color: ${props => props.theme.cohorteRed};
  font-size: .75rem;
  min-height: 1rem;
  flex: 1;
`

export const FieldError = ({ data, field }) =>
  data[field].errors[0]
    ? <FieldErrorMessage>{data[field].errors[0]}</FieldErrorMessage>
    : null

export const GlobalErrorMessage = styled.div`
  margin: 1rem 0;
  color: ${props => props.theme.cohorteRed};
`

export const InputContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  outline: none;
  padding-top: .3rem;
`

export const FormContainer = styled.div`
  //width: auto !important;
  width: auto;
  margin: 1rem;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin: 3rem 0;
  }
`

export const FormGroup = styled.div`
  margin: 0.5rem 0 1.8rem 0;
  > button {
    margin-right: .5rem;
  }
`

const CoupleInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${InputContainer}:last-child {
    justify-content: flex-end;
  }
`

export const LabelsContainer = styled(props => <CoupleInputContainer {...props} />)`
  display: flex;
  align-items: flex-end;
  ${SelectUi.SelectContainer} {
    padding: 0.5rem 1rem;
  }
  > div {
    &:nth-child(2) {
      width: auto;
    }
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    width: 28rem;
  }
`

export const LabelContainer = styled(props => <CoupleInputContainer {...props} />)`
  flex: 1;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    width: auto;
  }
  > div {
    margin-right: .8rem;
  }
  ${InputContainer} {
    margin-top: 0;
  }
`

export const FileContainer = styled.div`
  display: ${props => props.hideInputButton ? 'none' : 'block'};
  position: relative;
  color:  ${props => props.theme.grey};
  width: ${props => props.width || '6.25rem'};
  height: 6.25rem;
  background: ${props => props.theme.mediumGrey};
  border: 1px dashed ${props => props.theme.grey};
`

export const DocumentFileContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
`

export const ImageFileLabel = styled.label`
  font-size: 3rem;
  width: ${props => props.width || '6.25rem'};
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  &:hover {
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: .6;
  }
  &:disabled {
    cursor: not-allowed;
  }
`

const ImageFileInput = styled.input.attrs({ type: 'file' })`
  display: block;
  width: ${props => props.width || '6.25rem'};
  height: 6.25rem;
  clip: rect(0px 0px 0px 0px);
  position: absolute;
  left: 0;
  top: 0;
  z-index:100;
`

const FileInput = styled.input.attrs({ type: 'file' })`
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: relative;
	z-index: -1;
  box-sizing: border-box;
  justify-content: center;
  & + label {
    > div {
      margin-right: 1rem;
    }
    display: flex;
    padding: .65rem 1.4rem;
    border: 1px solid ${props => props.theme.patientGreen};
    color: ${props => props.theme.patientGreen};
    background-color: white;
    border-radius: 8px;
    align-items: center;
    outline: 0;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    text-decoration: none;
    cursor: pointer;
    min-width: 4rem;
    text-align: center;
  }
  &:disabled + label,
  &:disabled + label:hover {
    cursor: not-allowed;
    background: ${props => props.theme.grey2};
    color: ${props => props.theme.lightGrey};
    border: none;
    box-shadow: none;
  }
  &:focus + label,
  & + label:hover {
    box-shadow: inset 0px 0px 0px 1px ${props => props.theme.patientGreen};
  }
`

export const TextArea = styled.textarea`
  font-family: 'Montserrat', sans-serif;
  border: 0;
  border-radius: 8px;
  width: 100%;
  min-height: 7rem;
  font-size: .875rem;
  color: ${props => props.theme.darkBlue};
  padding: .6rem;
  outline: 0;
  box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
  padding-left: 1rem;
  padding-top: 1rem;
  line-height: 1.4rem;
  &:last-child:not(:first-child) {
    margin-top: 1rem;
  }
`

export const TextAreaResizable = styled(TextareaAutosize)`
  font-family: 'Montserrat', sans-serif;
  border: 0;
  border-radius: 8px;
  width: 100%;
  height: 3rem;
  min-height: 3rem;
  font-size: .875rem;
  color: ${props => props.theme.darkBlue};
  padding: .6rem;
  outline: 0;
  box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
  padding-left: 1rem;
  padding-top: 1rem;
  line-height: 1.4rem;
  &:last-child:not(:first-child) {
    margin-top: 1rem;
  }
`
const checkboxBackground = (props) => {
  if (props.colorized && props.disabled)
    if (props.checked || props.defaultChecked)
      return props.theme.primary
    else
      return props.theme.grey2
}

export const CheckboxContainer = styled.label`
  display: block;
  width: 1.5625rem;
  height: 1.5625rem;
  border-radius: 5px;
  border: 1px solid;
  box-sizing: border-box;
  border-color: ${props => props.checked ? props.theme.primary : props.theme.grey2};
  position: relative;
  display: flex;
  justify-content: center;
  background: ${props => props.checked
    ? props.theme.primary
    : 'transparent'
  };
  color: ${props => props.theme.white};
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  outline: none;
  flex-shrink: 0;
  align-items: center;
  background: ${props => checkboxBackground(props)};
  &:hover, &:focus {
    outline: none;
  }

  &:hover {
    border: 1px solid;
    border-color: ${props =>
    props.colorized && props.disabled && !props.checked
      ? props.theme.grey2
      : props.theme.primary
  };
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    outline: none;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
  }

  i {
    display: ${props => props.checked ? 'block' : 'none'};
    font-size: .7rem;
  }
`

export const Checkbox = ({ disabled, colorized, ...props }) => {
  const [checked, setChecked] = useState(props.checked || false)

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked, setChecked])

  return (
    <CheckboxContainer checked={checked} colorized={colorized} disabled={disabled}>
      <input type="checkbox" {...props} disabled={disabled} checked={checked} onChange={e => {
        props.onChange && props.onChange(e)
        setChecked(e.target.checked)
      }} />
      <IconOk />
    </CheckboxContainer>
  )
}

export const ImageFileUpload = ({
  id,
  children,
  hideInputButton,
  loading,
  icon,
  error,
  width,
  ...props
}) => {

  return <>
    <FileContainer hideInputButton={hideInputButton} width={width}>
      <ImageFileLabel htmlFor={id} width={width} disabled={loading || props.disabled}>
        {loading
          ? <Ui.Global.Loader />
          : '+'
        }
      </ImageFileLabel>
      <ImageFileInput id={id} disabled={loading || props.disabled} width={width} {...props} />
    </FileContainer>
    {children}
    {error &&
      <FieldErrorMessage>{error}</FieldErrorMessage>
    }
  </>
}

export const FileUpload = ({
  id,
  error,
  onChange,
  onClick,
  children,
  label,
  loading,
  ...props
}) =>
  <>
    <DocumentFileContainer>
      <FileInput id={id} onChange={onChange} onClick={onClick} disabled={loading || props.disabled} />
      <label htmlFor={id}>
        {loading && <Ui.Global.Loader />}{label}
      </label>
    </DocumentFileContainer>
    {children}
    {error &&
      <FieldErrorMessage>{error}</FieldErrorMessage>
    }
  </>

export const FieldEditButtons = styled.div`
  position: absolute;
  z-index: 6;
  right: 0;
  bottom: 0;
`

export const PasswordFieldViewButton = styled(props => <FieldEditButtons {...props} />)`
  bottom: 1rem;
`

export const FieldIconEye = styled(props => <IconUi.IconEye {...props} />)`
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  color: ${props => props.theme.grey};
  &:hover {
    color: ${props => props.theme.patientGreen};
  }
`

export const FieldIconOk = styled(props => <IconUi.IconOk {...props} />)`
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  color: ${props => props.theme.grey};
  &:hover {
    color: ${props => props.theme.patientGreen};
  }
`

export const FieldIconCancel = styled(props => <IconUi.IconClose {...props} />)`
  padding-top: .5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  color: ${props => props.theme.grey};
  &:hover {
    color: ${props => props.theme.patientGreen};
  }
`

const InputContainerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  /* margin-top:  ; */
`

const InputActionsContainer = styled.div`
  position: relative;
`

const TextAreaFieldEditButtons = styled.div`
  position: relative;
  z-index: 6;
  right: 0;
  flex: 1;
  > div {
    margin-top: .5rem;
    justify-content: flex-end;
  }
`

export const TextInput = ({
  isRed = false,
  isPurple = false,
  isGreen = false,
  id,
  label,
  error,
  htmlFor,
  name,
  size,
  placeholder,
  readOnly,
  hasFocus = !readOnly,
  italic,
  textInputType,
  isPlain,
  onClearField,
  onClick,
  noSubmit,
  onBlur,
  onInputBlur,
  value,
  pending,
  onRestoreValue,
  children,
  inputRef,
  tabIndex,
  noCancel = false,
  refs,
  type,
  ...restProps
}) => {

  useEffect(() => {
    if (refs?.current?.[name]) {
      refs.current[name].focus();
    }
    else if (inputRef)
      inputRef?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs, name]);

  const isPassword = type === 'password'
  const [showPassword, setShowPassword] = useState(!isPassword)

  return (
    <InputContainerWrapper id={id} inputRef={inputRef || refs} tabIndex={tabIndex}>
      <InputContainer>
        <>
          <Label htmlFor={name || htmlFor}>
            {label}
            <InputActionsContainer>
              <Input
                ref={inputRef || (node => { if (refs) refs.current[name] = node })}
                id={name || htmlFor}
                name={name}
                isGreen={isGreen}
                isPurple={isPurple}
                isRed={isRed}
                placeholder={placeholder}
                italic={italic}
                error={error}
                readOnly={readOnly}
                type={showPassword ? 'text' : 'password'}
                value={value}
                onCancel={onRestoreValue}
                onBlur={onInputBlur}
                noSubmit={noSubmit}
                {...restProps}
              />
              {hasFocus
                ? /(plain|new)Password/.test(htmlFor)
                  ? <PasswordFieldViewButton>
                    <Ui.Button.PasswordButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? 'Masquer' : 'Voir'}
                    </Ui.Button.PasswordButton>
                  </PasswordFieldViewButton>
                  : !noSubmit &&
                  <FieldEditButtons>
                    <FieldIconOk onClick={onBlur} />
                    {!noCancel &&
                      <FieldIconCancel onClick={onRestoreValue} />}
                  </FieldEditButtons>
                : null
              }
            </InputActionsContainer>
          </Label>
        </>
        {error &&
          <FieldErrorMessage><span className='input-error'>{error}</span></FieldErrorMessage>
        }
      </InputContainer>
    </InputContainerWrapper>
  )
}

export const TextAreaInput = ({
  htmlFor,
  withLabel = true,
  label,
  error,
  children,
  inputRef,
  refs,
  name,
  readOnly,
  onBlur,
  onRestoreValue,
  noSubmit,
  noCancel,
  hasFocus = !readOnly,
  footer = null,
  onKeyPress,
  tabIndex,
  ...restProps
}) => {
  useEffect(() => {
    if (refs?.current?.[name]) {
      refs.current[name].focus();
    }
    else if (inputRef)
      inputRef?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs, name]);

  return (
    <InputContainer inputRef={inputRef || refs} tabIndex={tabIndex}>
      {withLabel &&
        <Label htmlFor={htmlFor}>{label}</Label>
      }
      <TextArea id={htmlFor} ref={inputRef || (node => { if (refs) refs.current[name] = node })} onKeyDown={onKeyPress} {...restProps} />
      <Footer>
        {footer}
      </Footer>
      {hasFocus && !noSubmit &&
        <TextAreaFieldEditButtons>
          <LayoutUi.ActionButtonsContainer>
            <ButtonUi.ActionButton
              onClick={onBlur}
            >
              <IconUi.IconOk />
            </ButtonUi.ActionButton>
            {!noCancel &&
              <ButtonUi.ActionButton
                onClick={onRestoreValue}
              >
                <IconUi.IconClose />
              </ButtonUi.ActionButton>
            }
          </LayoutUi.ActionButtonsContainer>
        </TextAreaFieldEditButtons>
      }
      {error &&
        <FieldErrorMessage>{error}</FieldErrorMessage>
      }
    </InputContainer>
  )
}

export const TextAreaInputContainer = styled.div`
  margin-top: 0;
`

export const Footer = styled(props => <BasicElementUi.P {...props} />)`
  margin-top: .5rem;
  bottom: 0;
  position: absolute;
`

export const TextAreaInputResizable = ({
  htmlFor,
  withLabel = true,
  label,
  error,
  children,
  inputRef,
  refs,
  noSubmit = false,
  onBlur,
  onRestoreValue,
  onKeyPress,
  name,
  noCancel,
  tabIndex,
  isTitleWarning = false,
  ...restProps
}) => {

  useEffect(() => {
    if (refs?.current?.[name]) {
      refs.current[name].focus();
    }
    else if (inputRef)
      inputRef?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs, name]);

  return (
    <InputContainer inputRef={inputRef || refs} tabIndex={tabIndex}>
      {withLabel &&
        <Label htmlFor={htmlFor} isTitleWarning={isTitleWarning}>{label}</Label>
      }
      <TextAreaResizable
        id={htmlFor}
        onKeyDown={onKeyPress}
        name={name}
        ref={inputRef}
        onBlur={noSubmit ? onBlur : null}
        minRows="3"
        {...restProps}
      />
      <LayoutUi.FlexBox>
        {error &&
          <FieldErrorMessage>{error}</FieldErrorMessage>
        }
        {!noSubmit &&
          <TextAreaFieldEditButtons>
            <LayoutUi.ActionButtonsContainer>
              <ButtonUi.ActionButton
                onClick={onBlur}
              >
                <IconUi.IconOk />
              </ButtonUi.ActionButton>
              {!noCancel &&
                <ButtonUi.ActionButton
                  onClick={onRestoreValue}
                >
                  <IconUi.IconClose />
                </ButtonUi.ActionButton>
              }
            </LayoutUi.ActionButtonsContainer>
          </TextAreaFieldEditButtons>
        }
      </LayoutUi.FlexBox>
    </InputContainer>
  )
}

export const EditableTextInput = ({
  data,
  name,
  label,
  refs,
  tabIndex = "0",
  altContent,
  altText,
  altTextDisabledField,
  onEnterField,
  onLeaveField = () => { },
  updateField,
  onRestoreValue,
  onKeyPress,
  canEdit,
}) => {
  return (
    <Editable
      title={label}
      name={name}
      text={data[name].value}
      isEditMode={data[name].isFieldEdit}
      onClick={onEnterField(name, canEdit)}
      onBlur={onLeaveField(name)}
      childRef={refs}
      altContent={altContent}
      altText={canEdit ? altText : altTextDisabledField}
      canEdit={canEdit}
    >
      <Ui.Form.TextInput
        name={name}
        label={label}
        value={data[name].value}
        onChange={updateField(name)}
        onBlur={onLeaveField(name)}
        onRestoreValue={onRestoreValue(name, data[name].initialValue)}
        onKeyDown={onKeyPress}
        error={data[name].errors[0]}
        refs={refs}
        tabIndex={tabIndex || UniqueDigitId()}
      />
    </Editable>
  )
}

export const Labels = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: .5rem;
  width: 11.6rem;
  opacity: ${props => props.disabled ? '.4' : '1'};
`

export const Dropdown = styled(props => <Labels {...props} />)`
  width: 100%;
  height: 100%;
  padding-left: 3.5rem;
  & > div:first-child {
    font-weight: bold;
  }
`

export const DropdownItemLabel = styled.div`
  text-transform: uppercase;
  overflow-wrap: break-word;
  max-width: 9.4rem;
  line-height: ${props => props.lineHeight ? props.lineHeight : '1.1rem'};
  color: ${props => props.disabled ? props.theme.grey2 : props.theme.darkBlue};
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    max-width: 13rem;
  }
  margin-bottom: .2rem;
`

export const DropdownItemSubLabel = styled.div`
  font-weight: normal;
  max-width: 9.4rem;
`

const DropdownItemSupLabel = styled.div`
  color: ${props =>
    props.teleExpertiseType === 'staffs'
      ? props.theme.staffPurple
      : props.teleExpertiseType === 'opinions'
        ? props.theme.avisBlue
        : props.theme.cohorteRed
  };
  margin-bottom: .3rem;
`

export const RadioLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: 100%;
  height: 100%;
`

const RadioInput = styled.input.attrs({ type: 'radio' })`
  display: none;
  & + i {
    &::after {
      content: '';
      font-size: .55rem;
      text-align: center;
      line-height: 1.5625rem;
      display: block;
      height: 1.5625rem;
      width:  1.5625rem;
      border-radius: inherit;
      background: ${props => props.colorized && props.disabled && props.theme.grey2};
      cursor: ${props => props.disabled ? 'default' : 'pointer'};
    }
    &:hover {
      border-color: ${props => props.colorized && props.disabled && props.theme.grey2};
    }
  }
  &:checked + i {
    border-color: ${props => props.theme.primary};
    color: #fff;
    &::after {
      content: '\\e923';
      font-family: 'ShareConfrere';
      font-size: .55rem;
      text-align: center;
      line-height: 1.5625rem;
      display: block;
      height: 1.5625rem;
      width:  1.5625rem;
      border-radius: inherit;
      background: ${props => props.theme.primary};
    }
  }
`

export const RadioCheck = styled.i`
  display: inline-block;
  vertical-align: middle;
  width:  1.5625rem;
  height: 1.5625rem;
  border-radius: 50px;
  border: 1px solid ${props => props.theme.grey2};
  position: absolute;
  right: 1rem;
  &:hover {
    border: 1px solid ${props => props.theme.patientGreen};
  }
`

export const RadioCheckSingle = styled.i`
  display: inline-block;
  vertical-align: middle;
  width:  1.5625rem;
  height: 1.5625rem;
  border-radius: 50px;
  border: 1px solid ${props => props.theme.grey2};
  position: static;
  &:hover {
    border: 1px solid ${props => props.theme.primary};
  }
  &::after {
    background: white;
  }
`

export const CheckboxInput = styled.input.attrs(props => ({ type: 'checkbox' }))`
  display: none;
  &:checked + i {
    cursor: pointer;
    border-color: ${props => props.theme.patientGreen};
    color: #fff;
    &::after {
      content: '\\e923';
      font-family: 'ShareConfrere';
      font-style: normal;
      font-size: .625rem;
      text-align: center;
      line-height: 1.5rem;
      display: block;
      height: 1.5625rem;
      width:  1.5625rem;
      background: ${props => props.theme.patientGreen};
    }
  }
  &:disabled + i::after {
    background: ${props => props.theme.grey2};
    border: none;
  }
  &:disabled + i {
    border-color: ${props => props.theme.grey2};
    cursor: not-allowed;
  }
`

export const CheckboxAddTrashInput = styled.input.attrs(props => ({ type: 'checkbox' }))`
  display: none;
  &:checked + i {
    cursor: pointer;
    border-color: ${props => props.theme.patientGreen};
    color: #fff;
    &::after {
      content: '\\e92e';
      font-family: 'ShareConfrere';
      font-style: normal;
      font-size: 1rem;
      text-align: center;
      line-height: 1.5rem;
      border-radius: 4px;
      display: block;
      height: 1.5625rem;
      width:  1.5625rem;
      border: 1px solid ${props => props.theme.patientGreen};
      background: ${props => props.theme.patientGreen};
    }
  }
  &:disabled + i::after {
    background: ${props => props.theme.grey2};
    border-color: ${props => props.theme.grey2};
  }
  &:disabled + i {
    border-color: ${props => props.theme.grey2};
    cursor: default;
  }
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: ${props =>
    props.disabled
      ? 'not-allowed'
      : props.hasAction
        ? 'pointer'
        : 'default'
  };
  user-select: none;
  height: 100%;
`

const CheckboxDropdownLabel = styled(props => <CheckboxLabel {...props} />)`
  font-size: .9rem;
  width: 100%;
  height: 100%;
  cursor: ${props => props.disabled
    ? 'not-allowed'
    : 'pointer'
  };
`

const CheckboxDropdownInput = styled(props => <CheckboxInput {...props} />)`
  &:checked + i::after {
    height:  1.5625rem;

  }
  &:last-child {
    margin-bottom: 1rem;
  }
`

const CheckboxCheck = styled.i`
  display: inline-block;
  vertical-align: middle;
  width:  1.5625rem;
  height: 1.5625rem;
  border-radius: 4px;
  align-items: center;
  ${props => props.noborder ||
    css`border: 1px solid ${props => props.theme.grey2};`
  };
  position: absolute;
  border-radius: 5px;
  right: .8rem;
  top: .7rem;
  color: white;
  &:hover {
    ${props => props.noborder ||
    css`border: 1px solid ${props => props.theme.patientGreen};`
  };
  }
`

export const CheckboxDropdownCheck = styled(props => <CheckboxCheck {...props} />)`
  left: .5rem;
  top: 0;
`

export const ActionButtons = styled.div`
  position: relative;
  width: 3.5rem;
  display: inline-flex;
  flex-direction: row;
  right: .4rem;
  top: .2rem;
  bottom: 0;
  align-items: flex-end;
  justify-content: flex-end;
  ${IconUi.IconPatientSup} {
    margin-left: .3rem;
  }
`

export const CheckboxLargeContainer = styled.div`
  display: flex;
  position: relative;
  background: ${props => props.disabled || (props.canAddPatient && !props.canBeViewed) ? props.theme.lightGrey : 'white'};
  opacity: ${props => props.disabled ? '.8' : '1'};
  padding-top: .7rem;
  padding-bottom: .5rem;
  font-weight: 600;
  color: ${props => props.theme.darkBlue};
  border-radius: 5px;
  box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
  margin-top: .5rem;
  margin-bottom: .5rem;
  animation: fadein .5s;
  flex: 1 0;
  font-size: .9rem;
  border: 1px solid ${props => props.selected ? props.theme.patientGreen : 'transparent'};
  ${IconUi.IconPatientSup}, ${IconUi.IconCamera} {
    background-color: white;
    border-radius: 50px;
    border: 1px solid ${props => props.teleExpertiseType === 'staff'
    ? props.theme.staffPurple
    : props.teleExpertiseType === 'opinion'
      ? props.theme.avisBlue
      : props.theme.cohorteRed
  };
    padding: .4em;
    min-width: .5rem;
    min-height: .8rem;
    &:hover {
      opacity: .8;
    }
    color: ${props => props.teleExpertiseType === 'staff'
    ? props.theme.staffPurple
    : props.teleExpertiseType === 'opinion'
      ? props.theme.avisBlue
      : props.theme.cohorteRed
  };
  font-size: .7rem;
  }
  ${SearchBarUi.CheckboxIcon} {
    cursor: ${props => props.checked || !props.hasAction || !props.canBeViewed ? 'default' : 'pointer'};
  }
  ${CheckboxCheck} {
    align-items: flex-start;
  }
  label {
    height: 100%;
    z-index: 5;
  }
  ${props => props.checked || !props.hasAction ||
    css`
      &:hover {
        &::after {
          content: '\\e927';
          color: white;
          font-family: 'ShareConfrere';
          font-style: normal;
          font-size: 1rem;
          text-align: center;
          border-radius: 4px;
          line-height: 1.5rem;
          display: block;
          height: 1.5625rem;
          width:  1.5625rem;
          background: ${props => props.theme.patientGreen};
          border: 1px solid ${props => props.theme.patientGreen};
          position: absolute;
          right: .6rem;
        }
      }
  `
  };
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: ${props => props.disabled ? '.7' : '1'};
    }
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    flex-basis: auto;
    min-width: 15.5rem;
    max-width: 15.5rem;
    flex-shrink: 0;
    flex-grow: 1;
    margin-right: .7rem;
    &:not(:last-child) {
      display:flex;
      box-sizing: border-box;
      flex-shrink: 0;
    }
    &:hover {
      cursor: ${props => props.hasAction || props.onClick || props.isClickable || props.canBeViewed ? 'pointer' : 'default'};
    }
  }
`

export const RadioContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${props => props.theme.darkBlue};
  animation: fadein .5s;
  flex: initial;
  font-size: .9rem;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    flex: 1 0;
    flex-basis: auto;
    &:not(:last-child) {
      flex-shrink: 0;
    }
  }
`

export const CheckboxDropdownContainer = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
  min-height: 2.3rem;
  display: flex;
  align-items: flex-start;
  background: white;
  &:not(:first-child) {
    margin-top: .5rem;
  }
`

const Pro = styled(props => <IconPro {...props} />)`
  position: absolute;
  top: 6%;
  right: 3%;
`

export const Radio = ({
  name,
  value,
  label,
  disabled,
  defaultChecked,
  onClick,
  onBlur,
  colorized,
  children
}) =>
  <RadioContainer>
    <RadioLabel htmlFor={value}>
      <RadioInput
        id={value}
        name={name}
        onClick={onClick}
        colorized={colorized}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
      <RadioCheckSingle />
      {children}
    </RadioLabel>
  </RadioContainer>

export const RadioLarge = ({
  name,
  value,
  label,
  defaultChecked,
  onClick,
  onBlur,
  children
}) =>
  <CheckboxLargeContainer>
    <RadioLabel htmlFor={value}>
      <RadioInput
        id={value}
        name={name}
        onClick={onClick}
        onBlur={onBlur}
        value={value}
        defaultChecked={defaultChecked}
      />
      <RadioCheck />
      {children}
      <span>{label}</span>
    </RadioLabel>
  </CheckboxLargeContainer>

export const CheckboxLarge = ({
  label = null,
  subLabel = null,
  title,
  value = {},
  children,
  onChange,
  checked,
  disabled,
}) => checked
    ? <CheckboxLargeWrapper>
      <CheckboxLargeContainer
        title={title ? title : `${label} ${subLabel ? ` / ${subLabel}` : ''}`}
        checked={checked}
        disabled={disabled}
        hasAction={onChange}
      >
        <CheckboxLabel
          disabled={disabled}
          hasAction={onChange}
        >
          <CheckboxAddTrashInput
            name={label}
            onChange={onChange}
            checked={checked}
            disabled={disabled}
          />
          <CheckboxCheck
            checked={checked}
            noborder
          />
        </CheckboxLabel>
        {children}
        <Labels disabled={disabled}>
          {value && <DropdownItemSupLabel teleExpertiseType={value.type}>{expertiseNameFromType(value.type)}</DropdownItemSupLabel>}
          {label && <DropdownItemLabel><span className='checkbox-label'>{label}</span></DropdownItemLabel>}
          {subLabel && <DropdownItemSubLabel>{subLabel}</DropdownItemSubLabel>}
        </Labels>
      </CheckboxLargeContainer>
    </CheckboxLargeWrapper>
    : <CheckboxLargeWrapper>
      <CheckboxLargeContainer
        title={`${label} ${subLabel ? ` / ${subLabel}` : ''}`}
        checked={checked}
        hasAction={onChange}>
        <CheckboxLabel
          hasAction={onChange}
        >
          <CheckboxAddTrashInput
            name={label}
            onChange={onChange}
            checked={checked}
            disabled={disabled}
          />
          <CheckboxCheck
            checked={checked}
            noborder
          />
          {children}
          <Labels>
            {value && <DropdownItemSupLabel teleExpertiseType={value.type}>
              {expertiseNameFromType(value.type)}
            </DropdownItemSupLabel>}
            {label && <DropdownItemLabel>{label}</DropdownItemLabel>}
            {subLabel && <DropdownItemSubLabel>{subLabel}</DropdownItemSubLabel>}
          </Labels>
        </CheckboxLabel>
      </CheckboxLargeContainer>
    </CheckboxLargeWrapper>

const CheckboxLargeWrapper = styled.div`
  cursor: default;
  display: flex;
`

export const ButtonLarge = ({
  label = null,
  teleExpertiseType = null,
  teleExpertiseId = null,
  subLabel = null,
  children,
  onChange,
  onClick = null,
  to = null,
  checked,
  disabled,
  selected,
  badge = '',
  isClickable,
  canAddPatient,
  canBeViewed,
  isVisioconferencing = false,
  ...props
}) => {
  const history = useHistory();

  return <CheckboxLargeWrapper>
    <CheckboxLargeContainer
      title={`${label} ${subLabel ? ` / ${subLabel}` : ''}`}
      onClick={onClick || to
        ? e => to ? history.push(to) : onClick()
        : null
      }
      defaultCursor={!onClick && !to}
      selected={selected}
      isClickable={isClickable}
      teleExpertiseType={teleExpertiseType}
      canBeViewed={canBeViewed}
      canAddPatient={canAddPatient}
    >
      <CheckboxLabel
        hasAction={onClick}
      >
        <CheckboxInput
          name={label}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        {badge &&
          <Pro>{badge}</Pro>
        }
      </CheckboxLabel>
      {children}
      <Labels>
        {teleExpertiseType && <DropdownItemSupLabel teleExpertiseType={teleExpertiseType}>
          {expertiseNameFromType(teleExpertiseType)}
        </DropdownItemSupLabel>}
        {label && <DropdownItemLabel>{label}</DropdownItemLabel>}
        {subLabel && <DropdownItemSubLabel>{subLabel}</DropdownItemSubLabel>}
      </Labels>
      {canAddPatient &&
        <ActionButtons>
          {isVisioconferencing &&
            <IconUi.IconCamera
              onClick={(e) => {
                e.stopPropagation()
                window.open(`${privateSitePrefix}/videoconference/${teleExpertiseType}s/${teleExpertiseId}`, 'zoom')
              }
              }
              title="Participer à la visioconférence" />
          }
          <Link
            onClick={e => e.stopPropagation()}
            to={`${privateSitePrefix}/nouveau/patient?${teleExpertiseType}s=${teleExpertiseId}`}>
            <IconUi.IconPatientSup title="Ajouter un patient" />
          </Link>
        </ActionButtons>
      }
    </CheckboxLargeContainer>
  </CheckboxLargeWrapper>
}

export const CheckboxDropdown = ({
  label = null,
  subLabel = null,
  value,
  icon,
  children,
  onChange,
  checked,
  disabled,
  title,
  lineHeight = '1.4rem',
  ...props
}) =>
  <CheckboxDropdownContainer
    title={title}
    disabled={disabled}
  >
    <CheckboxDropdownLabel disabled={disabled}>
      <CheckboxDropdownInput
        name={label}
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <CheckboxDropdownCheck />
      {children}
      <Dropdown>
        <DropdownItemLabel disabled={disabled} lineHeight={lineHeight}>{icon}{label}</DropdownItemLabel>
        <DropdownItemSubLabel>{subLabel}</DropdownItemSubLabel>
      </Dropdown>
    </CheckboxDropdownLabel>
  </CheckboxDropdownContainer>
