import React, { useEffect } from 'react';
import { privateSitePrefix } from '../Util/Config';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";
import * as Login from '../State/Login';
import * as Patient from '../State/Patient';
import qs from 'qs';
import * as Ui from './Ui'
import styled from 'styled-components'

const CreatePatientFromParamsComponent = (props) => {
  const currentUser = useSelector(Login.selectUser)
  const creationSuccess = useSelector(Patient.selectCreationSuccess)
  const existingOwnedPatient = useSelector(Patient.selectExistingOwnedPatient)
  const patientExists = useSelector(Patient.selectPatientExists)
  const patientId = useSelector(Patient.selectPatientId)
  const dispatch = useDispatch();
  const patient = props.location.search
  const queryParams = qs.parse(patient, { ignoreQueryPrefix: true })
  const expertiseTypes = ['staffs', 'opinions', 'cohorts']
  const expertises = expertiseTypes
    .map(teleExpertise =>
      queryParams[teleExpertise]
        ? queryParams[teleExpertise].split(',').map(id => ({ type: teleExpertise, id: id.trim() }))
        : null
    ).filter(e => e)[0]

  useEffect(() => {
    if (!currentUser?.lastname?.toLowerCase().includes('nom à changer')) {
      const { firstname, lastname, sex: gender, birthdate } = queryParams
      dispatch(Patient.createPatient({ firstname, lastname, gender, birthdate, expertises }))
    }
  }, [currentUser])

  if (patientExists || existingOwnedPatient) {
    return <Redirect to={`${privateSitePrefix}/nouveau/patient/?${expertises[0].type}=${expertises[0].id}`} />
  } else if (creationSuccess) {
    return <Redirect to={`${privateSitePrefix}/patients/${patientId}`} />
  }

  return <Ui.Layout.PageLayout currentUser={currentUser}><Ui.Global.Loader /></Ui.Layout.PageLayout>
}

export default CreatePatientFromParamsComponent;
