import React, { useState } from 'react'
import QRCode from 'qrcode.react';
import * as Ui from '../../../Component/Ui'
import { fr } from '../../../Util/i18n';
import { uniq } from '../../../Util/Object'
import { privateSitePrefix, apiSymfony6Url } from '../../../Util/Config'
import DownloadableQrCode from '../../../Component/Ui/DowloadableQRCode';
import { todayAtMidnight } from '../../../Util/Date';
import { StaffPicture } from '../../../components/common/Picture';
import { Title } from '../../../components/common/PageLeftPanel';
import * as Icon from '../../../components/icons';
import * as Styled from '../Staff.styled'

const Summary = ({
    teleExpertiseName,
    data,
    loading,
    teleExpertiseId,
    errored,
    managersDisciplines,
    nextSession,
    nextSessionDateAtMidnight,
    publicUrl,
    DatePicker,
  }) => {
    const {
      name,
      description,
      pictureFileName,
      archive,
      endDate,
      isOutdated,
      recurrence,
      tags,
      disciplines,
      ownerTitle,
      ownerFullname,
      patientLimitLocked,
    } = data;
  
    const [showDownloadExportButton, setShowDownloadExportButton] = useState(false);
    const [showDownloadCustomExport, setShowDownloadCustomExport] = useState(false);
    const [onCustomExportButton, setOnCustomExportButton] = useState(false);
    const [exportStartDate, setExportStartDate] = useState(null);
    const [exportEndDate, setExportEndDate] = useState(null);

    const onVisioButtonClick = () => {
      nextSessionDateAtMidnight === todayAtMidnight
        ? window.open(`${privateSitePrefix}/videoconference/${teleExpertiseName}s/${teleExpertiseId}`, 'linkello')
        : alert.show(`Aucune visioconférence n'est programmée aujourd'hui. Prochaine session : ${nextSession.dateString === 'date à déterminer' ? 'date à déterminer' : ` le ${nextSession.dateString}`}`, { timeout: 5000 })
    }

    const tagList = tags.value.map((tags, i) =>
      <span key={`tags_${i}`}>{tags}</span>
    )
  
    const disciplineList = [...managersDisciplines, ...disciplines.value]
      .filter(uniq)
      .map((discipline, i) =>
        <span key={`discipline_${i}`}>{discipline}</span>
      )
  
    return (
      <Styled.LeftPanelContainer>
        <Ui.Layout.Panel>
          {!loading || errored
            ? <>
              <div className='flex items-center'>
                <StaffPicture
                  className='mr-2'
                  pictureFileName={pictureFileName.value}
                /><Title value={name.value} color='purple-primary' />
              </div>
              <div className='mt-2 text-[0.875rem]'>{description.value}</div>
              <div className='mt-8 text-[0.875rem]'>
                {!isOutdated.value && !archive.value
                  ? <>
                    <span className='text-[#42708A] font-semibold'>Prochaine séance :</span> <span id='summaryNextSession' className='font-semibold'>{
                      nextSessionDateAtMidnight === todayAtMidnight
                        ? `Aujourd'hui ${nextSession.hour !== '00:00' ? `à ${nextSession.hour} (ouverte)` : ''}`
                        : Object.values(nextSession).length === 0 || nextSession.dateString === 'date à déterminer' || !nextSession.id
                          ? 'Date à déterminer'
                          : `${nextSession.dateString} ${nextSession.hour && nextSession.hour !== '00:00' ? `à ${nextSession.hour} (ouverte)` : ''}`
                    }</span>
                    {(nextSessionDateAtMidnight === todayAtMidnight) && !nextSession.isFieldEdit
                      ? <button
                        onClick={onVisioButtonClick}
                        className='mt-3 py-2 px-4 rounded-lg bg-purple-primary font-semibold text-white leading-[1.313rem] text-[0.875rem]'
                      >
                        Visioconférence
                      </button>
                      : <div className='mt-3 leading-5 text-purple-primary'>
                        La visioconférence sera ouverte le jour de la prochaine séance
                      </div>
                    }
                  </>
                  : <span className='font-semibold'>{archive.value ? `${fr[teleExpertiseName].expertise} archivé` : `Séance du ${endDate.value} (archivée)`}</span>
                }
              </div>
              <div className='mt-8 text-[0.75rem]'>
                <span className='text-[#42708A] font-semibold'>Responsable :</span> <span className='font-semibold'>{ownerTitle.value} {ownerFullname.value}</span>
              </div>
              {disciplines.value.length > 0 &&
                <div className='mt-8 text-[0.75rem] text-[#42708A] font-semibold leading-[1.125rem]'>
                  <div className='mb-[0.75rem]'>Disciplines associées :</div>
                  <div className='inline-flex flex-wrap'>
                    {[...managersDisciplines, ...disciplines.value]
                      .filter(uniq)
                      .map((discipline, i) =>
                        <span className='mb-[0.5rem] px-[0.875rem] py-[0.125rem] border border-solid border-[#BAD1DE] rounded-full' key={`discipline_${i}`}>{discipline}</span>
                      )
                    }
                  </div>
                </div>
              }
              <div className='mt-8 text-[0.75rem] h-[1.125rem]'>
                <span className='text-[#42708A] font-semibold'>Portail :</span>
              </div>
              <div className='mt-2 inline-flex flex-wrap'>
                <span>
                  <a href={publicUrl} rel="noreferrer" target="_blank">
                    <DownloadableQrCode teleExpertiseName={name.value}>
                      <QRCode
                        value={publicUrl}
                        size={45}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        id="qrCodeCanvas"
                      />
                    </DownloadableQrCode>
                  </a>
                </span>
              </div>
              <div className='relative'>
                <div
                  className='
                    cursor-pointer mt-8 px-4 py-2 border rounded-lg border-solid border-purple-primary text-[0.875rem] leading-[1.5rem] flex items-center justify-between
                    hover:bg-[#F5F2FD]
                  '
                  onClick={() => {
                    setShowDownloadCustomExport(false);
                    setShowDownloadExportButton(!showDownloadExportButton);
                  }}
                >
                  <Icon.Download />
                  <span className='font-semibold pr-3 border-r border-solid border-purple-primary'>Télécharger les statistiques</span>
                  <Icon.ArrowDown />
                </div>
                {showDownloadExportButton &&
                  <div className='cursor-pointer absolute top-[5rem] w-full shadow-[0px_2px_8px_0px_rgba(46,88,119,0.10)] rounded-lg text-[#214152] text-[0.875rem] leading-[1.313rem]'>
                    <a
                      rel="noopener noreferrer"
                      download
                      href={`${apiSymfony6Url}/${teleExpertiseName}s/${teleExpertiseId}/kpi-download/01-01-${(new Date()).getFullYear()}/31-12-${(new Date()).getFullYear()}`}
                    >
                      <div className='
                        px-[1.25rem] py-[0.81rem] rounded-t-lg
                        hover:bg-[#F5F2FD]
                      '>Cette année ({(new Date()).getFullYear()})</div>
                    </a>
                    <a
                      rel="noopener noreferrer"
                      download
                      href={`${apiSymfony6Url}/${teleExpertiseName}s/${teleExpertiseId}/kpi-download/01-01-${(new Date()).getFullYear() - 1}/31-12-${(new Date()).getFullYear() - 1}`}
                    >
                      <div className='
                        px-[1.25rem] py-[0.81rem]
                        hover:bg-[#F5F2FD]
                      '>Année dernière ({(new Date()).getFullYear() - 1})</div>
                    </a>
                    <div
                      className='
                        px-[1.25rem] py-[0.81rem] rounded-b-lg flex justify-between
                        hover:bg-[#F5F2FD]
                      '
                      onMouseEnter={() => setOnCustomExportButton(true)}
                      onMouseLeave={() => setOnCustomExportButton(false)}
                      onClick={() => {
                        setShowDownloadExportButton(false);
                        setShowDownloadCustomExport(true);
                      }}
                    >
                      <span>Période personnalisée</span><Icon.ArrowRight className={onCustomExportButton ? 'opacity-100' : 'opacity-50'}/>
                    </div>
                  </div>
                }
                {showDownloadCustomExport &&
                  <div className='absolute top-[5rem] w-full shadow-[0px_2px_8px_0px_rgba(46,88,119,0.10)] rounded-lg shadow-lg'>
                    <div
                      className='relative cursor-pointer px-[1rem] py-[0.75rem] border-b border-solid border-[#F2F6F9] flex'
                      onClick={() => {
                        setShowDownloadExportButton(true);
                        setShowDownloadCustomExport(false);
                      }}
                    >
                      <Icon.FullArrowLeft/><span className='ml-[0.5rem] text-[0.875rem] leading-[1.5rem]'>Retour</span>
                    </div>
                    <div className='relative px-[1rem] py-[0.5rem]'>
                      <div className='w-full mb-[0.5rem] font-semibold text-[#214152] text-[0.875rem] leading-[1.5rem]'>Date de début</div>
                      <Styled.DatePickerBoxV2>
                        <DatePicker
                          value={exportStartDate}
                          clearIcon={null}
                          calendarIcon={<Icon.Calendar/>}
                          format="dd/MM/yyyy"
                          locale="fr-FR"
                          showLeadingZeros
                          onClick={e => e.stopPropagation()}
                          onChange={date => setExportStartDate(date)}
                          version='v2'
                        />
                      </Styled.DatePickerBoxV2>
                    </div>
                    <div className='relative px-[1rem] py-[0.5rem]'>
                      <div className='w-full mb-[0.5rem] font-semibold text-[#214152] text-[0.875rem] leading-[1.5rem]'>Date de fin</div>
                      <Styled.DatePickerBoxV2>
                        <DatePicker
                          value={exportEndDate}
                          clearIcon={null}
                          calendarIcon={<Icon.Calendar/>}
                          format="dd/MM/yyyy"
                          locale="fr-FR"
                          minDate={exportStartDate}
                          showLeadingZeros
                          onClick={e => e.stopPropagation()}
                          onChange={date => setExportEndDate(date)}
                          version='v2'
                        />
                      </Styled.DatePickerBoxV2>
                    </div>
                    <div className='p-[1rem]'>
                      <a
                        className={`${exportStartDate && exportEndDate ? 'cursor-pointer' : 'cursor-not-allowed	'} w-full border border-solid border-purple-primary rounded-lg bg-purple-primary hover:bg-[#8163D3] px-[1rem] py-[0.5rem] flex items-center justify-center text-[0.875rem] leading-[1.313rem]`}
                        rel="noopener noreferrer"
                        download
                        href={exportStartDate && exportEndDate && `${apiSymfony6Url}/${teleExpertiseName}s/${teleExpertiseId}/kpi-download/${String(exportStartDate.getDate()).padStart(2, '0')}-${String(exportStartDate.getMonth() + 1).padStart(2, '0')}-${exportStartDate.getFullYear()}/${String(exportEndDate.getDate()).padStart(2, '0')}-${String(exportEndDate.getMonth() + 1).padStart(2, '0')}-${exportEndDate.getFullYear()}`}
                      >
                        <Icon.Download color="#FFFFFF" /><span className='font-semibold text-white ml-[0.5rem]'>Télécharger</span>
                      </a>
                    </div>
                  </div>
                }
              </div>
            </>
            : <Styled.FullSpaceContainer>
              <Ui.Global.Loader />
            </Styled.FullSpaceContainer>
          }
        </Ui.Layout.Panel>
        <Ui.Layout.UpgradeMessage isTeleExpertiseLocked={patientLimitLocked.value} />
      </Styled.LeftPanelContainer>
    )
}

export default Summary;
