import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import * as ContactUs from '../State/ContactUs'
import * as Menu from '../State/Menu'
import * as Ui from './Ui'
import styled from 'styled-components'
import LoadingBox from './Ui/LoadingBox'
import SEOTags from './SEOTags';
import * as Icon from '../components/icons';

const LegalComponent = (props) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.login.currentUser)

  const [openInTabButtonHover, setOpenInTabButtonHover] = useState({'legalInformation': false, 'privacyPolicy': false, 'teleExpertiseCharter': false});

  useEffect(() => {
    dispatch(Menu.changeActive(null))

    return () => {
      dispatch(ContactUs.clean())
    }
  }, [dispatch])

  return (
    <>
      <SEOTags
        title="A propos - Skemeet"
      />
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <ContactUsContainer>
          <Ui.Layout.PageContentWrapper>
            <Ui.Layout.Content >
              <Ui.Layout.PaddedContent>
                <div className='mb-8 text-[#214152] text-[1.875rem] font-bold'>Documents légaux</div>
                <div className='flex flex-col'>
                  <div className='flex w-full items-center bg-white rounded-lg shadow-[0_2px_8px_0px_rgba(46,88,119,0.10)] h-[4.5rem] px-[2.5rem] px-[1.5rem] mb-3'>
                    <div className='flex justify-between w-full'>
                      <span className='flex items-center w-[30%] text-[#214152] text-[0.875rem] font-semibold'>Mentions légales et CGU</span>
                      <span className='flex items-center text-[#42708A] text-[0.75rem] font-semibold'>Version du 18/03/2024</span>
                      <a
                        className='flex items-center cursor-pointer'
                        onMouseEnter={() => setOpenInTabButtonHover({ ...openInTabButtonHover, legalInformation: true})}
                        onMouseLeave={() => setOpenInTabButtonHover({ ...openInTabButtonHover, legalInformation: false})}
                        href={`/CGU_Plateforme_de_connexion_centralisee_SKEZI.pdf`}
                        target='_blank'
                      >
                        <Icon.OpenInTab className={openInTabButtonHover.legalInformation ? 'opacity-100' : 'opacity-50'} /><span className={`ml-1 text-[#3BC3A8] text-[0.875rem] ${openInTabButtonHover.legalInformation ? 'underline' : 'no-underline'}`}>Afficher</span>
                      </a>
                    </div>
                  </div>
                  <div className='flex w-full items-center bg-white rounded-lg shadow-[0_2px_8px_0px_rgba(46,88,119,0.10)] h-[4.5rem] px-[2.5rem] px-[1.5rem] mb-3'>
                    <div className='flex justify-between w-full'>
                      <span className='flex items-center w-[30%] text-[#214152] text-[0.875rem] font-semibold'>Politique de confidentialité</span>
                      <span className='flex items-center text-[#42708A] text-[0.75rem] font-semibold'>Version du 08/04/2024</span>
                      <a
                        className='flex items-center cursor-pointer'
                        onMouseEnter={() => setOpenInTabButtonHover({ ...openInTabButtonHover, privacyPolicy: true})}
                        onMouseLeave={() => setOpenInTabButtonHover({ ...openInTabButtonHover, privacyPolicy: false})}
                        href={`/Politique_de_confidentialites_SKEZI.pdf`}
                        target='_blank'
                      >
                        <Icon.OpenInTab className={openInTabButtonHover.privacyPolicy ? 'opacity-100' : 'opacity-50'} /><span className={`ml-1 text-[#3BC3A8] text-[0.875rem] ${openInTabButtonHover.privacyPolicy ? 'underline' : 'no-underline'}`}>Afficher</span>
                      </a>
                    </div>
                  </div>
                  <div className='flex w-full items-center bg-white rounded-lg shadow-[0_2px_8px_0px_rgba(46,88,119,0.10)] h-[4.5rem] px-[2.5rem] px-[1.5rem] mb-3'>
                    <div className='flex justify-between w-full'>
                      <span className='flex items-center w-[30%] text-[#214152] text-[0.875rem] font-semibold'>Charte de la bonne utilisation de la téléexpertise</span>
                      <span className='flex items-center text-[#42708A] text-[0.75rem] font-semibold'>Version du 14/06/2024</span>
                      <a
                        className='flex items-center cursor-pointer'
                        onMouseEnter={() => setOpenInTabButtonHover({ ...openInTabButtonHover, teleExpertiseCharter: true})}
                        onMouseLeave={() => setOpenInTabButtonHover({ ...openInTabButtonHover, teleExpertiseCharter: false})}
                        href={`/Charte_de_Bon_Usage_de_la_Tele-Expertise.pdf`}
                        target='_blank'
                      >
                        <Icon.OpenInTab className={openInTabButtonHover.teleExpertiseCharter ? 'opacity-100' : 'opacity-50'} /><span className={`ml-1 text-[#3BC3A8] text-[0.875rem] ${openInTabButtonHover.teleExpertiseCharter ? 'underline' : 'no-underline'}`}>Afficher</span>
                      </a>
                    </div>
                  </div>
                </div>
              </Ui.Layout.PaddedContent>
            </Ui.Layout.Content>
          </Ui.Layout.PageContentWrapper>
        </ContactUsContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const ContactUsContainer = styled.div`
 ${Ui.BasicElement.H1} {
   margin-top: 2rem;
 }
 ${Ui.Layout.TelexpertiseThumbnailContainer} {
   margin-top: 1.5rem;
   ${Ui.BasicElement.H3} {
     margin-top: 0;
   }
 }
 ${Ui.Layout.NavLinkItem} {
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-right: .5rem;
  }
 }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    margin: 1rem;
  }
  ${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
    width: 100%;
  }
  ${Ui.Form.Input} {
    &:read-only {
      border-color: transparent;
      opacity: 1;
    }
  }
  ${Ui.Search.FiltersAndSorting} {
    justify-content: flex-end;
    &:first-child {
      margin-bottom: 1rem;
    }
  }
  ${Ui.Layout.Content} {
    padding-bottom: 2rem;
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Message.Body} {
      ${Ui.BasicElement.P} {
        margin: 0;
      }
    }
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding:0;
    }
  }
  ${Ui.BasicElement.H3} {
    margin-top: 1.5rem;
  }
  ${Ui.Select.SelectContainer} {
    width: 21rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      width: 85%;
    }
  }
  ${Ui.Button.Button} {
    margin-top: 1rem;
  }
  ${Ui.Form.Label} {
    ${Ui.SearchBar.SearchBarContainer} {
      margin-top: .6rem;
    }
  }
  ${Ui.Form.InputContainer} {
    margin-top: 1.5rem;
  }
  ${Ui.Layout.TelexpertiseLogo} {
    background-color: ${props => props.theme.patientGreen};
  }
  a {
    flex: 0;
    text-decoration: none;
  }
  ${Ui.Button.Button} {
    margin-top: 1rem;
  }
`

export default LegalComponent;
