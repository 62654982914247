import * as React from "react";

export const OpenInTab = ({
    color = '#3BC3A8',
    ...props
}) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
        d="M2.16667 15.5C1.70833 15.5 1.31597 15.3368 0.989583 15.0104C0.663194 14.684 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663194 1.31597 0.989583 0.989583C1.31597 0.663194 1.70833 0.5 2.16667 0.5H7.16667C7.40278 0.5 7.60069 0.579861 7.76042 0.739583C7.92014 0.899306 8 1.09722 8 1.33333C8 1.56944 7.92014 1.76736 7.76042 1.92708C7.60069 2.08681 7.40278 2.16667 7.16667 2.16667H2.16667V13.8333H13.8333V8.83333C13.8333 8.59722 13.9132 8.39931 14.0729 8.23958C14.2326 8.07986 14.4306 8 14.6667 8C14.9028 8 15.1007 8.07986 15.2604 8.23958C15.4201 8.39931 15.5 8.59722 15.5 8.83333V13.8333C15.5 14.2917 15.3368 14.684 15.0104 15.0104C14.684 15.3368 14.2917 15.5 13.8333 15.5H2.16667ZM13.8333 3.33333L6.66667 10.5C6.51389 10.6528 6.31944 10.7292 6.08333 10.7292C5.84722 10.7292 5.65278 10.6528 5.5 10.5C5.34722 10.3472 5.27083 10.1528 5.27083 9.91667C5.27083 9.68056 5.34722 9.48611 5.5 9.33333L12.6667 2.16667H10.5C10.2639 2.16667 10.066 2.08681 9.90625 1.92708C9.74653 1.76736 9.66667 1.56944 9.66667 1.33333C9.66667 1.09722 9.74653 0.899306 9.90625 0.739583C10.066 0.579861 10.2639 0.5 10.5 0.5H14.6667C14.9028 0.5 15.1007 0.579861 15.2604 0.739583C15.4201 0.899306 15.5 1.09722 15.5 1.33333V5.5C15.5 5.73611 15.4201 5.93403 15.2604 6.09375C15.1007 6.25347 14.9028 6.33333 14.6667 6.33333C14.4306 6.33333 14.2326 6.25347 14.0729 6.09375C13.9132 5.93403 13.8333 5.73611 13.8333 5.5V3.33333Z"
        fill={color}
        />
    </svg>
);
