import React from 'react';
import styled, { css } from 'styled-components';
import ShareConfrereVisuelMedecins from '../../Asset/Image/ShareConfrere-visuel-medecins-detouree.png';
import LogoSvg from '../../Asset/Image/logo-shareconfrere.svg';
import LogoIconSvg from '../../Asset/Image/logo-shareconfrere-icon.svg';
import HeaderWidget from './PortailHeader';
import * as Ui from '../Ui';
import { Link } from 'react-router-dom';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.header`
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 4rem;
  padding: 0 1rem;
  min-height: 4.375rem;
  box-shadow: ${props => props.theme.boxShadow.thick};
  background-color: white;
  img {
    height: 2.375rem;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    padding: 0 3rem;
  }
`

export const FlexPage = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column-reverse;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    flex-direction: row;
  }
`

export const Content = styled.div`
  flex: ${props => props.flex ? props.isHelpPage : '1'};
  margin: 0;
  margin-right: ${props => props.isHelpPage ? '1rem' : '0'};
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-left: 3rem;
  }
`

export const Figure = styled.div`
  display: none;
  background-image: url(${props => props.portalProPicture ? props.portalProPicture : ShareConfrereVisuelMedecins});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 -2rem;
  min-height: 350px;
  flex: ${props => props.isHelpPage ? '.8' : '1'};
  margin-right: ${props => props.isHelpPage ? '3rem' : '0'};
  margin-left: ${props => props.isHelpPage ? '2rem' : '0'};
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    display: block;
    height: 620px;
  }
`

export const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  font-size: .875rem;;
  padding: 0 .5rem;
  flex-direction: column;
  margin-top: auto;
  div {
    display: flex;
    align-items: center;
  }
  img {
    height: 2.2rem;
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-right: .8rem;
      height: 2.15rem;
    }
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    min-height: 4.375rem;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 3rem;
  }
`

export const FooterLinks = styled.div`
  justify-content: flex-end;
  margin-right: 3rem;
  ul {
    list-style: none;
    margin: 0;
    padding: 1rem 0;
    text-align: center;
  }
  ul > li {
    display: inline-block;
    border-right: 1px solid;
    border-color: ${props => props.theme.patientGreen};
    &:last-child {
      border: none;
    }
      padding-left: .3rem;
      padding-right: .3rem;
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  a {
    color: ${props => props.theme.darkBlue};
    text-decoration: none;
    font-size: 1rem;
    &:hover {
      color: ${props => props.theme.patientGreen};
    }
  }
`

const Logo = styled.div`
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    display: none !important;
  }
`

const LogoMini = styled.div`
  margin-top: 2rem;
  img {
    padding: 0 .5rem;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    display: none !important;
  }
`

export const Informations = styled.div`
  ${Ui.BasicElement.P} {
    line-height: 1rem;
    font-size: .65rem;
    margin-bottom: 0;
  }
  margin-top: 2rem;
`

export const Footer = ({ noLogo }) =>
  <FooterContainer>
    <div>
      {!noLogo &&
        <>
          <Logo> <img src={LogoSvg} alt="Shareconfrere" />&copy;&#160;{new Date().getFullYear()}</Logo>
          <LogoMini> <img src={LogoIconSvg} alt="Shareconfrere" />&copy;&#160;{new Date().getFullYear()}</LogoMini>
        </>
      }
    </div>
    <FooterLinks>
      <ul>
        <li><Link to="/contactez-nous">Contactez-nous</Link></li>
        <li><Link to="/aide">FAQ</Link></li>
        <li><a className='flex items-center cursor-pointer' href={`/CGU_Plateforme_de_connexion_centralisee_SKEZI.pdf`} target='_blank'>Mentions légales et CGU</a></li>
        <li><a className='flex items-center cursor-pointer' href={`/Politique_de_confidentialites_SKEZI.pdf`} target='_blank'>Confidentialité</a></li>
      </ul>
    </FooterLinks>
  </FooterContainer>

export const PageLayout = ({
  content,
  portalProPicture,
  fullWidth,
  fromLoginPage,
  fromRegistrationPage,
  isHelpPage,
  children
}) =>
  <>
    <HeaderWidget fromLoginPage={fromLoginPage} fromRegistrationPage={fromRegistrationPage} />
    <FlexPage>
      <Content isHelpPage={isHelpPage}>
        {content || children}
      </Content>
      {!fullWidth &&
        <Figure portalProPicture={portalProPicture} isHelpPage={isHelpPage} />
      }
    </FlexPage>
    <Footer />
  </>

export const Wrapper = styled.div`
  ${Header} {
    box-sizing: border-box;
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      position: fixed;
      width: 100%;
      z-index: 100;
      padding: 0 3rem;
    }
  }
  position: absolute;
  overflow: scroll;
  width: 100%;
  height: 100%;
  background-color: rgba(219, 230, 237, 0.3);
`


export const PortalMain = styled.div`
  justify-content: ${props => props.$loading ? 'center' : 'flex-start'};
  display: flex;
  min-height: 100vh;
  flex-flow: column nowrap;
  animation: fadein 1s;
  @keyframes fadein {
      from {
        opacity:0;
      }
      to {
        opacity:1;
      }
  }
`

export const PortalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: ${props => props.theme.lightGrey};
  box-sizing: border-box;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    min-height: auto;
  }
  ${Ui.Layout.StepperContainer} {
  left: calc(2rem + 359px);
  }
  ${Ui.Layout.Section} {
    padding-right: 0;
  }
  ${Ui.Layout.Form} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    }
  }
  ${props => props.noPadding && css`
    padding-top: 48px;
    padding-left: 359px;
    padding-right: 0;
  `}

  h2 {
    font-weight: 600;
    font-size: 1.125rem;
    color: ${props => props.theme.darkBlue};
    line-height: 1.6rem;
  }
  ${Ui.BasicElement.H3} {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  ${Ui.BasicElement.H2} {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  ${Ui.BasicElement.P} {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  ${Ui.BasicElement.H3}, ${Ui.Form.Label} {
    color: ${props => props.theme.darkBlue};
  }
  ${Ui.Form.Label} {
    &:disabled {
      font-weight: 700;
    }
  };
  ${Ui.Form.Input} {
    &:disabled {
      border-bottom: 1px dotted;
      border-color: ${props => props.theme.grey2};
    }
  };
  ${Ui.Layout.ActionButtonsContainer} {
    align-self: flex-end;
  }
`

export const PortalSideBar = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: fixed;
  width: auto;
  height: 100vh;
  flex-shrink: 0;
  box-shadow: ${props => props.theme.boxShadow.right};
  box-sizing: border-box;
  top: 0;
  background: ${props => props.theme.white};
  padding-top: 85px;
  padding-left: 1.9rem;
  padding-right: 1.9rem;
  padding-bottom: 1.9rem;
  overflow: scroll;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    min-height: calc(100vh - 300px);
    width: 25rem;
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    position: relative;
    height: auto;
    padding-top: 0;
    padding-bottom: 1rem;
  }
  ${Ui.BasicElement.H1} {
    font-size: 2rem;
    margin: .7rem 0;
    margin-top: 3rem;
  }
  ${Ui.BasicElement.H2} {
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin-top: 2.5rem;
  }
  ${Ui.BasicElement.P} {
    line-height: 1.6rem;
  }
  ${Ui.Button.Back} {
    flex: initial;
  }
`

export const PortalContentContainer = styled.div`
  padding-left: 0;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-top: 4.375rem;
   // padding-left: 25rem;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`

export const TeleExpertiseBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const TeleExpertiseChoice = styled.div`
  display: flex;
  cursor: pointer;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;

  @media only screen and (max-width: ${props => props.theme.device.tablet}) {
    display: flex;
    margin-bottom: 2rem;
    justify-content: center;
  }

  ${Ui.Icon.IconAvis} {
    margin-right: 1rem;
    color: ${props => props.theme.avisBlue};
    font-size: 2rem;
  }

  ${Ui.Icon.IconMessage} {
    margin-right: 1rem;
    color: ${props => props.theme.staffPurple};
    font-size: 2rem;
  }

  ${Ui.Icon.IconCohorte} {
    margin-right: 1rem;
    color: ${props => props.theme.cohorteRed};
    font-size: 2rem;
  }
`

export const WordsBlock = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const ImportantIconWords = styled(props => <Ui.BasicElement.P {...props} />)`
  display: block;
  font-size: 1.125rem;
  padding-right: .4rem;
`

export const IconWords = styled(props => <Ui.BasicElement.P {...props} />)`
  display: block;
  font-size: 1.125rem;
`

export const CircleButton = styled.div`
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 22.5px;
  border: solid 0.1rem ${props => props.theme.grey};
`

export const CircleButtonSelected = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 22.5px;
  border: solid 0.3rem ${props => props.theme.lightBlue};
  background-color: ${props => props.theme.avisBlue};
`

export const PortalProPicture = styled(props => <Figure {...props} />)`
  min-height: 120px !important;
  margin-left: 0 !important;
  height: 120px;
  background-position: left;
  flex: initial;
`

export const Banner = styled.div`
  background: url(${props => props.backgroundSrc}) no-repeat white;
  background-position: ${props =>
    props.alignment === 1
      ? 'top'
      : props.alignment === 2 || !props.isStretch
        ? 'center'
        : 'bottom'
  };
  box-shadow: ${props => props.isPortalBanner ? 'rgb(134 174 197 / 20%) 0px 0px 5px 1px' : 'none'};
  background-size: ${props => props.isStretch ? 'cover' : 'auto 90%'};
  width: 100%;
  left: 0;
  position: relative;
  height: 8rem;
  opacity: .8;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    min-height: 10rem;
  }
`
